import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import { Container } from 'react-bootstrap'

export default function NotFound() {
    <Helmet>
            <title>404 Page Not Found</title>
            <meta name="description" content="Tidy LLC 404 Page Not Found" />
            <meta property="og:title" content="Tidy LLC | Multi-Custodial Services" />
            <meta property="og:type" content="website" />
            <meta property="og:description" content="Tidy LLC 404 Page Not Found" />
            <meta property="og:image" content="../static/aboutus/aboutUs_1@2x.jpg" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:url" content="https://tidyllc.com/404" />
            <link rel="canonical" href="https://tidyllc.com/404" />
      </Helmet>
    return (
        <Layout>
            <Container>
                <section id="nf-wrap">
                    <h1 className="nf-header">404 Page Not Found</h1>
                    <h2 className="nf-sub-header">Sorry this page doesn't exist</h2>
                    <Link className="nf-link" to="/">Go back home</Link>
                </section>
                
            </Container>
        </Layout>
    )
}
